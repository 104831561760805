html, body {
  height: 100vh;
  font-family: "Oxygen";
  font-size: 14px;
}
.row{
  margin-right: 0px;
  margin-left: 0px;
}

.col-sm-10{
padding-right: 0px;
padding-left: 0px;
}
/* span{
font-size:15px;
} */
table th {
  font-size: 14px;
}
.order_div{
padding:20px;
}
@media (max-width: 1000px){
.orders__filters-item {
    margin-right: 0px;
    display: flex;
    align-items: center;
    margin: 10px;
}
}
.header__controls.open {
right: 0;
display: flex;
}
.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.pagination{
margin-left: auto;
}
dl, ol, ul {
margin-bottom: 0rem;
}
.product-form{
width: 100%;
padding-bottom: 0px;
}
.product-text{
height: 50px;
padding-left: 20px;
/* margin-left:50px; */
}
.dataTable{
border-style: solid;
border-width: 1.5px;
border-color: #E8EEF1;
}
.table-striped tbody tr:nth-of-type(odd) {
background-color: rgba(0, 243, 255, 0.05);
}
.check-box{
width: 80%;
padding-left: 80px;
}
.purprice-title{
padding-top:20px;
padding-bottom:20px;
font-size: 16px;
line-height: 10px;
margin-bottom: 0px;
font-weight: bold;
}
.react-datepicker-wrapper{
width:100%
}
.myDatePicker {
  width:100%;
  height:calc(1.5em + .75rem + 2px);;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #ced4da;
  padding: 10px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .myDatePicker:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
.product-details{
padding-bottom: 20px;
}
.btn-small{
width:10%;
}
.transport{
padding-bottom: 50px;
}
.sales-details{
  max-width: 750px;
  padding-bottom:20px;
}
.add-row{
display: inline-block;
margin: 0.375rem;
}
.prurprice-dataTable {
  border-style: solid;
  border-width: 1.5px;
  border-color: #E8EEF1;
  padding-top: 0px;
  margin-top: 0px;
}
.purchase-order-table{
height: 600px;
}
.login-page{
text-align: center;
}
.login-page-div{
margin-top: calc(23%);
height: 450px;
background-color: white;
}
.login-form{
padding-top:30px
}
.orders__filters-search {
width: calc(60%);
}
.sign-in{
font-size: 15px;
width: 160px;
height: 35px;
margin-bottom: 20px;
background-color: #2dafe5;
color:#ffffff;
}
.sign-in:hover{
font-size: 15px;
width: 160px;
height: 35px;
margin-bottom: 20px;
background-color: #ffffff;
color: #2dafe5;
}
.login-side-div{
height: 100%;
width: 25%;
background-color: #2dafe5;
}
.login-page-div{
padding: 0px;
position: fixed;
top: 50%;
left: 50%;
width: 47em;
margin-top: -17em;
margin-left: -24em;

}
.login-side-grad{
left:0;
margin-top: 370px;
}
.error-messages {
padding: 5px;
color: #DC2C2C!important;
font-size: 13px;
font-weight: 700;
}
.statu-item {
padding-left:20px
}
.dashboard__top{
padding-top:20px
}
.credit-form{
padding-bottom:10px
}
.payment{
margin-top: 8px;
width: 60%;
height: 53%;
}
.file-table{
height: 260px;
}
.modal_button{
width: 100px;
}
.addQuestion{
  width: 100%;
  color: #333333;
  height: 150px;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px 0;
  align-items: center;
  justify-content: space-around;
  display: flex;
  float: none;
}
.vertical-center {
  padding:0;
  margin-top: calc(23%);
  height: 500px;
  background-color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn {
  text-transform: none;
}
.btn-success.dropdown-toggle {
  background-color: #ffffff!important;
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem #ffffff
}
.btn-success.dropdown-toggle:hover, .btn-success.dropdown-toggle:focus {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem #ffffff
}
.btn-success.dropdown-toggle:focus{
  background-color: #ffffff !important;
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 0.2rem #ffffff;
  border-color: #ffffff;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #ffff;
  border-color: #ffff;
}
.btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  box-shadow: 0 0 0 0 #ffff;
  background-color: #ffff !important;
}
.select-lang-class{
  padding-right: 5px;
  width: 79px;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
  /* -webkit-appearance; */
  border-radius: 5px;
  -webkit-appearance:button;
}
.dataTables_wrapper .dataTables_filter input:active {
  margin-left: 0.5em;
  /* -webkit-appearance; */
  border-radius: 5px;
  -webkit-appearance:button;
}
@media (max-width: 576px){
  .select-lang-class{
    width: 79px;
    padding-bottom:10px
  }
  }
.react-confirm-alert-button-group {
  justify-content: flex-end;
}
.statu-item{
  cursor: pointer
}
table.dataTable.no-footer {
  border-bottom: 2px solid #ece9e9;
}
.input-detail{
  margin-left:20px
}
.place-and-orders__top {
  border-bottom: 1px solid #2dafe5;
}
.product-detail-table{
  padding-top:20px
}
.header__user-img{
  margin-left:5px
}
.customer-name{
  cursor: "pointer"; 
  color:'#004388';
  font-Size:"13px";
  font-Weight:'bold';
}
@media (min-width: 1200px){
  .container {
      max-width: 100%;
  }
}
@media (min-width: 576px){
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
}
.card-container-col{
  padding: 0px;
  box-shadow: -5px 0 10px 2px rgba(0, 0, 0, .1);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 2px 0px 2px 2px;
  border-color:rgba(0,0,0,.125);

}
.card-container-col-finance{
  padding: 0px;
  box-shadow: -5px 0 10px 2px rgba(0, 0, 0, .1);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color:rgba(0,0,0,.125);
}
.card{
  border: 0px
}
.codex-address{
  margin-Top:-10px;
  padding-Left:10px;
}
.codex-content{
  font-size:13px;
  display:flex;
  padding-top:10px;
  padding-bottom:10px
}
.panel-collapse{
  padding-top:20px
}
.customer-detail{
  margin-right: 0px;
}
.active-icon{
  color: #2EC826;
  margin-Top:3px;
  padding-right:7px
}
.contact-detail-header{
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.card-header{
  font-weight: bold;
}
.dataTables_wrapper {
  padding-top: 10px
}
.modal-content{
  width: 110%;
}
.craete-visitreport-row{
  margin: 0;
  padding: 30px;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(15, 72, 179, 0.06), 0 20px 30px -10px rgba(15, 72, 179, 0.2);
}
.create-visitreport-form{
  padding:20px
}
.required-asterisk{
  color: red
}
.create-report-header{
  font-size:20px;
  background-color:#3d55d2;
  padding: 13px;
  color: white;
  font-weight: bold;
}
.create-report-header-title{
  font-weight: bold;
  font-size:20px;
  padding:20px
}
.select-visit-answer{
  height: 50;
}
.credit-history{
  margin-top:20px;
}
.css-1szy77t-control:hover {
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}
.css-1szy77t-control{
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
  border-color: #80bdff !important;
}
:focus {
  outline: -webkit-focus-ring-color 3px;
}
input:focus {
  outline-offset: 0px;
}
.aprove-Hundegger{
  height:270px
}
.company-info-credit{
  height: 133px;
}
.company-info-title{
  display: block;
}
.user-info-credit {
  margin-top: 10px;
  height: 44px;
}
.dashboard__top-small::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 48px;
  background-color: #2dafe5;
  border-top-right-radius: 4px;
}
.page-loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}
td:first-child {
  border-left: 1px solid #dddddd;
}
td:last-child {
  border-right: 1px solid #dddddd;
}
.table.table th{
  padding: .75rem !important;
  vertical-align: middle;
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #fff !important;
}
.table td {
  padding: 10px 10px !important;
  vertical-align: middle;
  border-top: 1px solid #fff !important;
}
.bottom-datatable {
  display: flex;
}
.dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_info, .dataTables_wrapper .dataTables_processing, .dataTables_wrapper .dataTables_paginate {
  color: #585858;
  padding-top: 10px;
}
.dataTables_wrapper .dataTables_info{
  padding-top: 15px;
}
.dataTables_info {
  margin-left: auto;
  margin-top: 7px;
  margin-right: 20px !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #E2E3E4;
  border-radius: 4px;
  background-color: #F5F6F7;
}
.dataTables_length select {
  height: 28px;
  width: 46px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
}
.table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after{
  right: 14px;
}
